<template>
  <div id="inspectorId">
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm quan trắc'"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel"
        @clickAdd="$router.push({ name: 'environmental-monitoring-add' })"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field == 'code'">
            <b-badge variant="light-primary">{{ props.row.code }}</b-badge>
          </span>
          <span v-else-if="props.column.field == 'startDate'">
            <span>{{ props.row.startDate | formatDateToDDMM }}</span>
          </span>
          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="Edit3Icon"
                size="18"
                class="text-body"
                @click="showModalEdit(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body ml-2"
                @click="deleteItem(props.row.id)"
              />
            </span>
          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { formatFullName, formatDateToDDMM } from '@core/utils/filter'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'

import ShowFile from '@/components/show-file/ShowFile.vue'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ShowFile,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      totalRecord: 0,
      dataTable: [],
      columns: [
        {
          label: 'MÃ TRẮC QUAN',
          field: 'code',
          sortable: false,
        },
        {
          label: 'DOANH NGHIỆP TRẮC QUAN',
          field: 'bussinesName',
          sortable: false,
        },
        {
          label: 'NGÀY THỰC HIỆN',
          field: 'startDate',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '175px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        search: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      showBtnMultiDelete: false,
    }
  },
  created() {
    this.fetchDataListMonitoring(this.urlQuery)
  },
  methods: {
    ...mapActions('environmentalMonitoring', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),

    // Lấy danh sách quan trắc người lao động
    async fetchDataListMonitoring(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_OBSERVATION_MONITOR, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataListMonitoring(this.urlQuery)
    },

    // tìm kiếm lộ trình đào tạo
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = val
        this.fetchDataListMonitoring(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.search = ''
        this.fetchDataListMonitoring(this.urlQuery)
      }
    },

    // Mở modal tạo và chỉnh sửa kiểm định viên
    showModalEdit(id) {
      this.$router.push({ name: 'environmental-monitoring-edit', params: { id } })
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      await axiosApiInstance.delete(ConstantsApi.DELETE_OBSERVATION_MONITOR, {
        params: { model: JSON.stringify(this.deleteIds) },
      }).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Xóa thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
        this.fetchDataListMonitoring(this.urlQuery)
      }).catch(e => {
        this.$root.$bvToast.toast(e.response.data?.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    async getValidData(dataInput) {
      const model = {
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcel(model)
      // await this.$router.push({ name: 'import-equipment-management' })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
